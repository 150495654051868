exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-selected-projects-airline-neutral-design-system-jsx": () => import("./../../../src/pages/selected-projects/airline-neutral-design-system.jsx" /* webpackChunkName: "component---src-pages-selected-projects-airline-neutral-design-system-jsx" */),
  "component---src-pages-selected-projects-cubix-design-system-jsx": () => import("./../../../src/pages/selected-projects/cubix-design-system.jsx" /* webpackChunkName: "component---src-pages-selected-projects-cubix-design-system-jsx" */),
  "component---src-pages-selected-projects-mendeley-homepage-redesign-jsx": () => import("./../../../src/pages/selected-projects/mendeley-homepage-redesign.jsx" /* webpackChunkName: "component---src-pages-selected-projects-mendeley-homepage-redesign-jsx" */),
  "component---src-pages-selected-projects-mrm-duplicate-management-jsx": () => import("./../../../src/pages/selected-projects/mrm-duplicate-management.jsx" /* webpackChunkName: "component---src-pages-selected-projects-mrm-duplicate-management-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

